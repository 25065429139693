<template>
    <div class="c-select-language-page">
        <ul class="c-select-language-page__list">
            <li class="c-select-language-page__item" v-for="language in this.languageMenuItems" :key="language.id">
                <a href="#" class="c-select-language-page__link" role="button" @click.prevent="setAppLang(language)">
                  <img :src="language.icon" :alt="language.langName" class="c-select-language-page__icon" aria-hidden="true">
                  <div class="c-select-language-page__name">{{ language.langName }}</div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'

export default {
  mixins: [pageMixin],
  created () {
    this.$xp.history.addHomePage(this.pageData.ID, `/page/${this.pageData.ID}`, this.pageData.Title, this.pageData.Homepage ? this.pageData.Homepage.ParentID : 0, this.pageData.Homepage && this.pageData.Homepage.AncestorIDs ? this.pageData.Homepage.AncestorIDs : []) // is added in http
  },
  methods: {
    setAppLang (lang) {
      window.localStorage.setItem('nfcLangCode', lang.langCode)
      this.$xp.polyglot.setLanguage(lang.langCode, lang.path)
      this.$router.push(lang.url)
    }
  },
  computed: {
    languageMenuItems () {
      const items = []
      this.pageData.LanguageVirtualFolders.forEach((item) => {
        items.push({
          id: item.ID,
          langName: item.LangName,
          langCode: item.LangCode,
          langId: item.LangID,
          path: item.Path,
          url: '/page/' + (item.PageID ? item.PageID : this.pageData.NextPageID),
          icon: item.Icon
        })
      })
      return items
    }
  }
}
</script>
